:root {
  color-scheme: dark;
}

body {
  overflow-x: hidden;
  background-color: #190505;
  /* background-color: #141414;
  background-image: linear-gradient(135deg, #0c0c0c 25%, transparent 25%),
          linear-gradient(225deg, #0c0c0c 25%, transparent 25%),
          linear-gradient(45deg, #0c0c0c 25%, transparent 25%),
          linear-gradient(315deg, #0c0c0c 25%, #000000 25%);
  background-position: 10px 0, 10px 0, 0 0, 0 0;
  background-size: 20px 20px;
  background-repeat: repeat; */
  font-family: 'Montserrat', sans-serif;
}

.bleep-search-bar{
  padding-inline: "20px";
  background-color: "rgb(20, 20, 20)"; 
  color: "white";
  border: "none";
}

.bg-pattern{
  background:
  radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,#000000 68% 70%,#0000 72%) 60px 60px/calc(2*60px) calc(2*60px),
  radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,#000000 68% 70%,#0000 72%) 60px 60px/calc(2*60px) calc(2*60px),
  radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,#000000 68% 70%,#0000 72%) 0 0/calc(2*60px) calc(2*60px),
  radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,#000000 68% 70%,#0000 72%) 0 0/calc(2*60px) calc(2*60px),
  repeating-conic-gradient(rgba(255, 255, 255, 0) 0 25%,#0000 0 50%) 0 0/calc(2*60px) calc(2*60px),
  radial-gradient(#0000 66%,#000000 68% 70%,#0000 72%) 0 calc(60px/2)/60px 60px
  rgba(255, 255, 255, 0);

  opacity: 0.08;
}

a {
  color: rgba(255, 81, 0, 1) 100%;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.bleep-card {
  outline : none;
  cursor: pointer;
  transition: all 0.1s ease-in;
  animation: fadeInUp ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.bleep-card:hover {
  outline: 1px solid rgba(255, 81, 0, 1);
  /* box-shadow: 0px 0px 20px 0px rgba(255, 81, 0, 1); */
  /* transform: translateY(-5px); */
  /* transform: scale(0.98); */
}

.bleep-card:hover .card-body {
  border-radius: 20px;
  background-color: #3f1b0c;
}

.bleep-settings-card {
  outline : none;
  cursor: pointer;
  padding-inline: 20px;
  padding-block: 20px;
  margin-bottom: 20px;
}

.bleep-card-title {
  font-size: 20px;
  margin: 0px;
  font-weight: bold;
  color: white;
}

.bleep-card-text {
  font-size: 20px;
  margin: 0px;
  color: white;
}

.bleep-settings-card:hover {
  outline: 1px solid rgba(255, 81, 0, 1);
}

/* Bleep post */
.status {
  /* font-size: min(2vw, 12px); */
  font-size: 12px;
  line-height: 8px;
  padding: 10px;
  padding-inline: 20px;
  border-radius: 50px;
  text-align: center;
  font-weight: thin;
  word-wrap: break-word;
}

.status-mini {
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.spin {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.resolved {
  background-color: rgb(20, 173, 0);
  /* background-image: linear-gradient(315deg, rgb(19, 159, 0) 0%, rgb(0, 88, 0) 60%, rgb(21, 182, 0)); */
  color: white;
}

.action {
  background-image: linear-gradient(315deg, rgb(255, 175, 46) 0%, rgb(255, 234, 0) 60%, rgb(255, 162, 13));
  color: black;
}

.noaction {
  background-color: rgb(233, 27, 27);
  color: white;
}

.actions {
  padding-bottom: 20px;
}

/* other styles */
.btn-sector {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  height: 60px;
  margin-bottom: 5px;
  font-size: 12px;
  text-align: left;
}
  
.btn-sector:hover {
  background: rgb(255, 154, 0);
  background: linear-gradient(
        180deg,
        rgba(255, 154, 0, 1) 0%,
        rgba(255, 81, 0, 1) 100%
      );
  border: none;
  color: white;
}

.btn-report {
  background: rgba(255, 123, 0, 0.711);
  color: white;
  z-index: 1000;
}

.btn-report:hover {
  background: rgb(255, 154, 0);
  background: linear-gradient(
    60deg,
    rgba(255, 154, 0, 1) 0%,
    rgba(255, 81, 0, 1) 100%
  );
  border: solid 2px rgb(255, 255, 255);
  color: white;
}

.btn-report:focus {
  color: white;
}

.btn-floating-report {
  width: 80px; 
  height: 80px;
  border-radius: 100px;
  /* background-image: linear-gradient(315deg, rgb(255, 175, 46) 0%, rgb(255, 234, 0) 60%, rgb(255, 162, 13)); */
  background: linear-gradient(0deg, rgba(255, 175, 46, 1) 0%, rgba(255, 81, 0, 1) 100%);
  color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  display: flex;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 0.5s ease;
}

.btn-floating-report:hover {
  background-color: #46271c;
  background-image: none;
  color: white;
}

.btn-floating-verify {
  width: 80px; 
  height: 80px;
  border-radius: 100px;
  background: #FCF1E6;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 0.5s ease;
}

.btn-floating-verify:hover {
  background-color: #f9ccb5;
  color: white;
}

.card {
  border-width: 0px;
  background-color: #24120B;
  border-radius: 20px;
}

.card-body {
  padding-bottom: 0px;
  border-radius: 20px;
  background-color: #2e170e;
  color: white;
}

.card-title {
  font-size: 15px;
}

.card-text {
  font-size: 12px;
}

.clickable{
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.clickable:hover{
  box-shadow: 0px -2px 31px 3px rgba(0,0,0,0.37);
-webkit-box-shadow: 0px -2px 31px 3px rgba(0,0,0,0.37);
-moz-box-shadow: 0px -2px 31px 3px rgba(0,0,0,0.37);
}

.comment {
  padding-inline: 1.25rem;
  padding-block: 0.5rem;
  border-radius: .25rem;
  background-color: #0000006c;
  color: white;
  font-size: 12px;
  margin-bottom: 15px;
}

.comment-bleeper {
  border: 1px solid #000000;
  border-left-color: #ff6600;
  border-left-width: .25rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: left;
}

.comment-admin {
  border: 1px solid #000000;
  border-left-color: #44ff00;
  border-left-width: .25rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: left;
}

.form-control:focus {
  border-color: #FF0000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(255, 81, 0);
}

.shimmer {
  -webkit-mask:linear-gradient(-60deg,#000 40%,#0005,#000 60%) right/300% 100%;
  animation: shimmer 3s infinite;
}

.bleep-image {
  height: 200px;
  width: 200px;
  object-fit: cover;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.bleep-image:hover{
  /* border: solid 2px rgb(255, 81, 0); */
  transform: scale(0.98);
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
  0%, 100% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
}

/* Comments */
.comment-bar {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.comment-container {
  /* border-radius: 30px;
  padding: 15px; */
  width: 100%;
}

.comment-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comments-closed {
  color: #ffffff;
  text-align: center;
  width: 100%;
}

.bleep-primary-btn {
  background-color: #7a2405;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.bleep-primary-btn:hover {
  background-color: #6b3b26;
}

.sign-in-card{

  animation: fadeInUp ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.sign-in-btn{
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 15px;
  text-decoration: none;
}

.btn-dark {
  background-color: #7a240557;
  color: white;
  border: none;
}

.btn-dark:hover {
  background-color: #6b3b26;
}

.btn-gradient{
  background: rgb(255, 175, 46);
  background: linear-gradient(0deg, rgba(255, 175, 46, 1) 0%, rgba(255, 81, 0, 1) 100%);
  color: white;
  border: none;
}

.btn-gradient:hover{
  box-shadow: 0px 0px 10px 0px #ff6a00;
}

.account-btn{
  background: rgb(255, 175, 46);
  background: linear-gradient(0deg, rgba(255, 175, 46, 1) 0%, rgba(255, 81, 0, 1) 100%);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 15px;
  text-decoration: none;
}

.account-btn:hover{
  box-shadow: 0px 0px 10px 0px #ff6a00;
}

.bleep-btn{
  background-color: #f7e2cc;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 50px;
  padding: 20px 50px;
  font-size: 20px;
}

.bleep-btn:hover{
  box-shadow: 0px 0px 10px 0px #ffffff;
}

.comment-file-btn {
  background-color: #7a240557;
  color: white;
  border: none;
  border-radius: 20px;
  height: 30px;
  padding-inline: 10px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in;
}

.comment-file-btn:hover {
  background-color: #ff4d00;
}

.comment-cancel-btn {
  background-color: #7a240557;
  color: white;
  border: none;
  border-radius: 20px;
  height: 30px;
  padding-inline: 15px;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in;
}

.comment-cancel-btn:hover {
  background-color: #ff4d00;
}

.comment-post-btn{
  background-color: #ff4d00;
  color: white;
  border: none;
  border-radius: 20px;
  height: 30px;
  padding-inline: 15px;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in;
}

.comment-post-btn:hover{
  background-color: #bd430f;
}

.comment-input{
  width: 100%;
  background-color: #00000000;
  color: #ffffff;
  font-size: 12px;
  border-radius: 10px;
  padding: 10px;
  border: none;
  outline: none;
}

.comment-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.comment-input:focus {
  border: 1px solid #ffffff;
}

/* Bleep footer */
.bleep-footer{
  background-color: #2d0d02;
  /* background: linear-gradient(
    180deg,
    rgb(81, 28, 0) 0%,
    #2d0d02 40%
  ); */
  color: white;
  margin: 0;
  padding: 0;
  padding: 20px;
  text-align: center;
  font-size: 12px;
  position: relative;
}

.border-bottom {
  border-bottom: 1px solid #3c180b !important;
}

.footer-btn{
  color: white;
  text-decoration: none;
}

.footer-btn:hover{
  color: #ff6600;
}

/* Bleep navbar */
.bleep-navbar {
  background: #f7e2cc;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.nav-btn {
  color: #3f1b0c;
  padding: "20px";
  text-decoration: none;
}

.nav-btn:hover {
  color: #ff6600;
}

.bleep-subnavbar {
  display: flex; /* Set the container as a flexbox */
  /* margin-top: 10px;
  margin-inline: 5em;
  border-radius: 20px; */
  /* background: #000000; */
  background: linear-gradient(
    90deg,
    rgba(255, 175, 46, 1) 0%,
    rgba(255, 81, 0, 1) 100%
  );
  justify-content: center; /* Center the links in the navbar */
  align-items: center;
  padding: 10px; /* Add some padding for spacing */
  color: white;
}

.bleep-subnavbar div {
  margin: 0 10px; /* Add horizontal spacing between the links */
}

.bleep-subnavbar a {
  color: white; /* Ensures link text is visible */
  text-decoration: none; /* Removes the underline */
}

.bleep-subnavbar a:hover {
  color: #8a3700;
}


.dropdown-left:hover {
  outline: 2px solid #873f00;
}

.dropdown-item:hover {
  background-color: #ff6a00;
  color: white;
}

@media only screen and (max-width: 777px) {
}
@media only screen and (min-width: 778px) {
}